<template>
  <div class="table">
    <div class="container">
      <el-tabs value="1" @tab-click="tabClick">
        <el-tab-pane label="已预约" name="1"></el-tab-pane>
        <el-tab-pane label="已通知" name="2"></el-tab-pane>
        <el-tab-pane label="已核销" name="3"></el-tab-pane>
        <el-tab-pane label="取消预约" name="4"></el-tab-pane>
      </el-tabs>

      <div class="handle-box" style="float: left;">
        <el-radio-group v-model="pageInfo.isToday" @change="changeToday">
          <el-radio :label="1">今日</el-radio>
          <el-radio :label="0">全部</el-radio>
        </el-radio-group>
        <el-divider direction="vertical"></el-divider>
        <el-button icon="el-icon-refresh" @click="onRefresh">刷新</el-button>
        <el-button
          v-loading="exportLoading"
          type="primary"
          icon="el-icon-upload2"
          @click="exportToExcel"
        >
          导出
        </el-button>
        <el-button type="danger" icon="el-icon-delete" @click="delAll">
          批量删除
        </el-button>
      </div>
      <div style="float: right;">
        <el-form
          ref="queryForm"
          :model="query"
          :inline="true"
          label-width="120px"
        >
          <el-form-item prop="name">
            <el-input
              v-model="query.name"
              placeholder="请输入预约人姓名"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item prop="policeNoOrTelephone">
            <el-input
              v-model="query.policeNoOrTelephone"
              placeholder="请输入警号、联系方式"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item prop="orderDate">
            <el-date-picker
              v-model="query.orderDate"
              align="right"
              type="date"
              placeholder="选择预约日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
              clearable
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="search">
              搜索
            </el-button>
            <el-button icon="el-icon-reset" @click="resetForm('queryForm')">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </div>

      <div style="clear: both;"></div>
      <el-table
        v-loading="listLoading"
        :data="list"
        element-loading-text="正在加载 ..."
        border
        fit
        highlight-current-row
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="预约人姓名" prop="name" />
        <el-table-column label="警号" prop="policeCard" />
        <el-table-column label="联系电话" prop="telphone" />
        <el-table-column label="提交时间" prop="createTime" sortable />
        <el-table-column label="预约项目" prop="orderType" />
        <el-table-column label="预约日期" prop="orderDate" sortable />
        <el-table-column label="预约地点" prop="address" width="200" />
        <el-table-column label="操作" width="300" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status === 1"
              type="success"
              size="small"
              @click="onExamine(scope.row)"
            >
              审核通知
            </el-button>
            <el-button
              v-if="scope.row.status === 2"
              size="small"
              @click="onCancel(scope.row)"
            >
              核销
            </el-button>
            <el-button type="danger" size="small" @click="onDel(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          :current-page="pageInfo.startPage"
          :page-sizes="[10, 20, 30, 50, 100, 200]"
          :page-size="pageInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageInfo.total"
          @size-change="onSizeChange"
          @current-change="onPageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAllList,
  sendMessage,
  updateStatus,
  delAppointment,
  sendAuditMessage
} from '@/api/haircut';
import { export_json_to_excel } from '@/assets/js/Export2Excel';
import { Message, MessageBox } from 'element-ui';
import { constants } from 'fs';
import Dict from '@/components/common/dict';

export default {
  name: 'HaircutList',
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          }
        ]
      },
      query: {
        policeNoOrTelephone: '',
        name: '',
        orderDate: '',
        status: 1
      },
      pageInfo: {
        startPage: 1,
        pageSize: 50,
        total: 0,
        policeNoOrTelephone: '',
        name: '',
        orderDate: '',
        status: 1,
        isToday: 1
      },
      list: [],
      listLoading: true,
      dlgLoading: false,
      selectDlgShow: false,
      exportLoading: false,
      multipleSelection: []
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    tabClick(tab, event) {
      let status = tab.name;
      this.pageInfo.status = status;
      this.query.status = status;
      this.onRefresh();
    },
    changeToday(v) {
      this.pageInfo.isToday = v;
      this.fetchData();
    },
    onPageChange(page) {
      this.pageInfo.startPage = page;
      this.fetchData();
    },
    onSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.fetchData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    search() {
      this.pageInfo.policeNoOrTelephone = this.query.policeNoOrTelephone;
      this.pageInfo.name = this.query.name;
      this.pageInfo.orderDate = this.query.orderDate;
      this.pageInfo.status = this.query.status;
      this.fetchData();
    },
    onRefresh() {
      this.fetchData();
    },
    //查询列表
    fetchData() {
      this.listLoading = true;
      getAllList(this.pageInfo).then(response => {
        this.list = response.data.records;
        this.pageInfo.total = response.data.total;
        this.pageInfo.startPage = response.data.current;
        this.pageInfo.pageSize = response.data.size;
        this.listLoading = false;
      });
    },
    //批量删除
    delAll() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请至少选择一条数据');
        return;
      }
      this.$confirm('您确定要删除所选择的数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var ids = [];
        this.multipleSelection.forEach(item => {
          ids.push(item.id);
        });
        delAppointment({ ids: ids })
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.fetchData();
          })
          .catch(() => {
            this.$message.error('删除错误！');
          });
      });
    },
    //删除
    onDel(data) {
      this.$confirm('您确定要删除此条记录吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delAppointment({ id: data.id })
          .then(resp => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.fetchData();
          })
          .catch(() => {
            this.$message.error('删除错误！');
          });
      });
    },
    //审核
    onExamine(row) {
      this.$confirm('您确定要审核通知该用户吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          sendMessage({ id: row.id })
            .then(resp => {
              if (resp.code == 200) {
                this.$message({
                  message: resp.msg,
                  type: 'success'
                });
                this.onRefresh();
              } else {
                this.$message.error(resp.msg);
              }
            })
            .catch(() => {
              console.log('服务器响应失败');
            });
        })
        .catch(() => {
          console.log('cancel');
        });
    },
    //发送短信通知
    sendMsg(row) {
      this.$confirm('您确定要发送短信通知给该用户吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          sendAuditMessage({ id: row.id })
            .then(resp => {
              if (resp.code === 200) {
                this.$message({
                  message: resp.msg,
                  type: 'success'
                });
                this.onRefresh();
              } else {
                console.log(resp);
                this.$message.error(resp.msg);
              }
            })
            .catch(() => {
              this.$message.error('服务器响应失败');
            });
        })
        .catch(() => {
          console.log('cancel');
        });
    },
    //核销
    onCancel(row) {
      this.$confirm('请先确定该用户已通知，再核销?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          updateStatus({ id: row.id, status: 3 })
            .then(resp => {
              if (resp.code == 200) {
                this.$message({
                  message: resp.msg,
                  type: 'success'
                });
                this.onRefresh();
              } else {
                this.$message.error(resp.msg);
              }
            })
            .catch(() => {
              this.$message.error('服务器响应失败');
            });
        })
        .catch(() => {
          console.log('cancel');
        });
    },
    //excel数据导出
    exportToExcel() {
      this.exportLoading = true;
      require.ensure([], () => {
        const tHeader = [
          '预约人名称',
          '警号',
          '联系方式',
          '提交时间',
          '预约项目',
          '预约日期',
          '预约状态'
        ];
        const filterVal = [
          'name',
          'policeCard',
          'telphone',
          'createTime',
          'orderType',
          'orderDate',
          'status'
        ];
        const list = this.list;
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, '理发预约');
        this.exportLoading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      for (var i = 0; i < jsonData.length; i++) {
        jsonData[i].status = Dict.getText(
          jsonData[i].status,
          Dict.appointment_status
        );
      }
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}
.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}
</style>
