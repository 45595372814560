import request from '@/utils/request';

/**
 * 获取所有的列表
 * @param {Object} data
 */
export function getAllList(data) {
  return request({
    url: '/haircut/appointment/getAllList',
    method: 'post',
    data
  });
}

/**
 * @param {Object} 发送通知
 */
export function sendMessage(data) {
  return request({
    url: '/haircut/appointment/sendMessage',
    method: 'post',
    data
  });
}

/**
 * @param {Object} 修改状态
 */
export function updateStatus(data) {
  return request({
    url: '/haircut/appointment/updateStatus',
    method: 'post',
    data
  });
}

/**
 * 查看单个详情
 * @param {Object} data
 */
export function getAppointmentInfo(data) {
  return request({
    url: '/haircut/appointment/getAppointmentInfo',
    method: 'post',
    data
  });
}

/**
 * 删除操作
 */
export function delAppointment(data) {
  return request({
    url: '/haircut/appointment/del',
    method: 'post',
    data
  });
}

/**
 * 发送短信通知
 */
export function sendAuditMessage(data) {
  return request({
    url: '/haircut/appointment/sendAuditMessage',
    method: 'post',
    data
  });
}
